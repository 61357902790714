<template>
  <div class="default-pagination">
    <!-- 分页按钮 start -->
    <div class="pagination-left">
      <div class="pagination-sum">共{{ total }}条</div>
      <el-select
        v-model="pageSize"
        :popper-append-to-body="false"
        @change="handleSelectChange"
      >
        <el-option
          :label="item + '条/页'"
          :value="item"
          v-for="item in pageSizeOptions"
          :key="item"
        ></el-option>
      </el-select>
    </div>
    <div class="pagination-right">
      <el-pagination
        background
        :current-page="page"
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :pager-count="5"
        @current-change="handlePaginationChange"
      />
      <div class="page-box">
        <span class="page">
          {{ page }}
        </span>
        <span>/{{ Math.ceil(total / pageSize) }}</span>
      </div>
      <div class="jump">
        <span class="text">到第</span>
        <el-input
          oninput="value=value.replace(/^(0+)|[^\d]+/g,''))"
          v-model="putPage"
          @blur="putPage = $event.target.value"
        />
        <span class="text">页</span>
        <div class="confirm-btn" @click="handlePaginationClick">确定</div>
      </div>
    </div>
    <!-- 分页按钮 end -->
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    // 总条数
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pageSizeOptions: [15, 30, 45, 60], // 每页条数组
      page: 1, // 页码
      pageSize: 15, // 每页条数
      putPage: "", //跳转到的页码
    };
  },
  methods: {
    /**
     * 提供父层调用更新页码和每页条数
     * @page 页码
     * @pageSize 每页条数
     */
    setData(page, pageSize) {
      if (page) {
        this.page = page;
      }
      // 筛选传入和每页条数是否在每页条数组中
      let pageSizeOption = this.pageSizeOptions.find((item) => {
        return item == pageSize;
      });
      // 当存在赋值于每页条数上
      if (pageSizeOption) {
        this.pageSize = pageSize;
      }
    },

    /**
     * page 改变时会触发
     * @page 页码
     * @pageSize 每页条数
     */
    handlePaginationChange(page) {
      this.putPage = "";
      let pageSize = this.pageSize;
      this.page = page;
      /**
       * 触发父层方法
       */
      this.$emit("change", page, pageSize);
    },

    /**
     * pageSize 改变时会触发
     * @pageSize 每页条数
     */
    handleSelectChange(pageSize) {
      this.putPage = "";
      let page = this.page;
      let totalPage = parseInt(this.total / pageSize);
      if (this.total % pageSize != 0) {
        totalPage += 1;
      }
      if (totalPage < page && totalPage > 0) {
        page = totalPage;
      }
      this.page = page;
      /**
       * 触发父层方法
       */
      this.$emit("change", page, pageSize);
    },
    /**
     * pageSize 点击确定
     */
    handlePaginationClick() {
      let putPage = this.putPage;
      if (putPage == "") {
        return;
      }
      let page = this.putPage;
      let totalPage = parseInt(this.total / this.pageSize);
      if (this.total % this.pageSize != 0) {
        totalPage += 1;
      }
      if (totalPage < page && totalPage > 0) {
        page = totalPage;
      }
      if (putPage > page) {
        this.putPage = Number(page);
      }
      this.page = Number(page);

      /**
       * 触发父层方法
       */
      this.$emit("change", this.putPage, this.pageSize);
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
