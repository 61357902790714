<template>
  <div class="customer-container">
    <div class="customer-title-box">
      <div>我的客户</div>
      <div class="title-right" @click="handleAddCustomer">
        <img src="@/assets/img/user/addcustomer.png" width="100%" height="100%" @dragstart.prevent class="img-setting" />
        <span class="customer">新建客户</span>
      </div>
    </div>
    <div class="divider"></div>
    <div class="customer-content">
      <div v-if="total == 0" style="margin-top: 150px">
        <empty name="暂无数据"></empty>
      </div>
      <div class="customer-box" v-show="total != 0">
        <div class="customer-box-item" v-for="(item, index) in tableData" :key="index" @click="handleDetail(item)">
          <div class="customer-left">
            <img class="item-icon" v-if="item.logoUrl" @dragstart.prevent :src="item.logoUrl" alt="" />
            <div class="img-left-logo" v-else style="background-color: #6daae2">
              <div class="logo-img">{{ item.logoImg }}</div>
            </div>
            <div class="customer-info">
              <div class="customer-info-top">
                <el-tooltip effect="light" :content="item.clientName" placement="bottom-start">
                  <div class="info-top">
                    <span>{{ item.clientName }}</span>
                  </div>
                </el-tooltip>

                <div class="customer-right">
                  <div v-show="!item.consumerShareId" class="customer-item-option" @click.stop="shareNewCustomer(item)">
                    <img @dragstart.prevent src="@/assets/img/user/gongxiang.png" alt="" width="100%" height="100%"
                      class="item-icon" />
                    <span class="item-txt" style="color: #8590a6">共享</span>
                  </div>
                  <div v-show="item.consumerShareId" class="customer-item-option" @click.stop="sharedCustomer(item)">
                    <img @dragstart.prevent src="@/assets/img/user/yigongxiang.png" alt="" width="100%" height="100%"
                      class="item-icon" />
                    <span class="item-txt">已共享</span>
                  </div>
                  <div class="customer-item-option" v-show="item.consumerId" @click.stop="editCustomer(item)">
                    <img @dragstart.prevent src="@/assets/img/user/xiugai.png" alt="" width="100%" height="100%"
                      class="item-icon" />
                    <span class="item-txt">修改</span>
                  </div> 
                  <div class="customer-item-option" @click.stop="delCustomer(item)">
                    <img @dragstart.prevent src="@/assets/img/user/del.png" alt="" width="100%" height="100%"
                      class="item-icon" />
                    <span class="item-txt">移除</span>
                  </div>
                </div> 
              </div> 
              <div class="info-bottom">
                <div class="bottom-left" @click.stop="handleDetail(item, 1)">
                  招标动态({{ item.tenderCount || 0 }})
                </div>
                <div class="bottom-right" @click.stop="handleDetail(item, 2)">
                  中标动态({{ item.bidWinnerCount || 0 }})
                </div>
              </div>
            </div>
          </div>

          <div class="share-tag" v-show="item.isShare == '10011001'">
            <img @dragstart.prevent src="@/assets/img/user/sharetag.png" alt="" width="100%" height="100%"
              class="tag-icon" />
          </div>
          <span class="tag-txt" v-show="item.isShare == '10011001'">共享</span>
        </div>
      </div>
      <div class="pagination-box">
        <pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0">
        </pagination>
      </div>
      <!-- 新建/修改客户   dialog -->
      <div class="add-client">
        <Modal :visible.sync="visible" @reset="resetFields" class="dialog-style">
          <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
            {{ customerTitle }}
          </div>
          <el-form :model="dataPO" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
            <el-row>
              <el-col :span="24">
                <el-form-item label="客户性质" prop="clientType" class="title-item">
                  <el-radio-group v-model="dataPO.clientType" @change="handleChangeCustomerType">
                    <el-radio :disabled="typeDisabled" v-for="item in customerTypeList" :key="item.value"
                      :label="item.value" :value="item.value">{{ item.label }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-show="dataPO.clientType == '60661001'">
                <el-form-item label="统一社会信用代码" prop="creditCode" class="code-item">
                  <el-input v-model.trim="dataPO.creditCode" placeholder="请输入统一社会信用代码" @input="$forceUpdate()"
                    :disabled="columnUpdateState.creditCode == 1"></el-input>
                  <span class="tongbu-style" v-show="customerTitle == '新建客户'" @click="hangdleRenew(dataPO.creditCode)">
                    <img src="@/assets/img/user/shuaxin.png" width="100%" height="100%" @dragstart.prevent />
                    <span>同步数据</span>
                  </span>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-show="dataPO.clientType == '60661001'">
                <el-form-item label="企业名称" prop="clientName" class="title-item">
                  <el-input v-model.trim="dataPO.clientName" placeholder="请输入企业名称" maxlength="50" :show-word-limit="true"
                    :disabled="disabled ||
                      !!this.renewData.clientName ||
                      columnUpdateState.clientName == 1
                      "></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-show="dataPO.clientType == '60661002'">
                <el-form-item label="客户名称" prop="clientName" class="title-item">
                  <el-input v-model.trim="dataPO.clientName" placeholder="请输入客户名称" maxlength="50"
                    :show-word-limit="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-show="dataPO.clientType == '60661001'">
                <el-form-item label="企业logo" prop="logo" class="upload-item">
                  <!-- 非必填 -->
                  <el-upload :class="{
                    uploadSty: showBtnDealImg,
                    disUploadSty: noneBtnImg
                  }" ref="uploadPic" :action="uploadImgUrl" :headers="headerObj" list-type="picture-card"
                    :auto-upload="true" :on-change="handleChangePic" :on-success="handlePicSuccess"
                    :before-upload="beforePicUpload" :file-list="fileList" :limit="1" accept=".jpg, .jpeg, .png"
                    :disabled="disabled ||
                      !!this.renewData.logo ||
                      columnUpdateState.logo == 1
                      ">
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{ file }">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span v-if="!imgDisabled || columnUpdateState.logo != 1" class="el-upload-list__item-delete"
                          @click="handleRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                    <div slot="tip" class="el-upload__tip">
                      只能上传一张jpg/jpeg/png文件，且不能超过5M
                    </div>
                  </el-upload>
                  <el-dialog width="37%" :visible.sync="imgDialogVisible" :append-to-body="true">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-show="dataPO.clientType == '60661001'">
                <el-form-item label="企业法人" prop="legalPerson" class="title-item">
                  <el-input v-model.trim="dataPO.legalPerson" placeholder="请输入企业法人" maxlength="10" :show-word-limit="true"
                    :disabled="disabled ||
                      !!this.renewData.legalPerson ||
                      columnUpdateState.legalPerson == 1
                      "></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-show="dataPO.clientType == '60661002'">
                <el-form-item label="客户负责人" prop="legalPerson" class="title-item">
                  <el-input v-model.trim="dataPO.legalPerson" placeholder="请输入客户负责人" maxlength="10"
                    :show-word-limit="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-show="dataPO.clientType == '60661001'">
                <el-form-item label="注册资本（万元）" prop="registeredCapital" class="title-item">
                  <el-input v-model.trim="dataPO.registeredCapital" placeholder="请输入注册资本" :disabled="disabled ||
                    !!this.renewData.registeredCapital ||
                    columnUpdateState.registeredCapital == 1
                    " maxlength="30"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-show="dataPO.clientType == '60661001'">
                <el-form-item label="成立日期" prop="establishDate" class="title-item">
                  <el-date-picker v-model="dataPO.establishDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                    :disabled="disabled ||
                      !!this.renewData.establishDate ||
                      columnUpdateState.establishDate == 1
                      ">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话" prop="phone" class="title-item">
                  <el-input v-model.trim="dataPO.phone" placeholder="请输入联系电话" oninput="value=value.replace(/[^\d]/g,'')"
                    @blur="dataPO.phone = $event.target.value" :disabled="(disabled && dataPO.clientType == '60661001') ||
                      (!!this.renewData.phone &&
                        dataPO.clientType == '60661001') ||
                      (dataPO.clientType == '60661001' &&
                        columnUpdateState.phone == 1)
                      "></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="企业地址" prop="province" class="select-item">
                  <el-select clearable v-model="dataPO.province" @change="choseProvince" placeholder="请选择省份" :disabled="(disabled && dataPO.clientType == '60661001') ||
                    (!!this.renewData.province &&
                      dataPO.clientType == '60661001') ||
                    (dataPO.clientType == '60661001' &&
                      columnUpdateState.province == 1)
                    ">
                    <el-option v-for="(item, index) in provinceList" :key="index" :label="item.name"
                      :value="item.code"></el-option></el-select> </el-form-item></el-col>
              <el-col :span="6">
                <el-form-item label="" prop="city" class="select-items">
                  <el-select clearable v-model="dataPO.city" @change="choseCity" placeholder="请选择市区" :disabled="(disabled && dataPO.clientType == '60661001') ||
                    (!!this.renewData.city &&
                      dataPO.clientType == '60661001') ||
                    (dataPO.clientType == '60661001' &&
                      columnUpdateState.city == 1)
                    ">
                    <el-option v-for="(item, index) in cityList" :key="index" :label="item.name"
                      :value="item.code"></el-option></el-select> </el-form-item></el-col>
              <el-col :span="4">
                <el-form-item label="" prop="county" class="select-itemss">
                  <el-select clearable v-model="dataPO.county" @change="$forceUpdate()" placeholder="请选择区县" :disabled="(disabled && dataPO.clientType == '60661001') ||
                    (!!this.renewData.county &&
                      dataPO.clientType == '60661001') ||
                    (dataPO.clientType == '60661001' &&
                      columnUpdateState.county == 1)
                    ">
                    <el-option v-for="(item, index) in countyList" :key="index" :label="item.name"
                      :value="item.code"></el-option></el-select> </el-form-item></el-col>
              <el-col :span="24">
                <el-form-item label="" prop="address" class="title-item">
                  <el-input v-model.trim="dataPO.address" placeholder="请输入详细地址" maxlength="100" :show-word-limit="true"
                    :disabled="(disabled && dataPO.clientType == '60661001') ||
                      (!!this.renewData.address &&
                        dataPO.clientType == '60661001') ||
                      (dataPO.clientType == '60661001' &&
                        columnUpdateState.address == 1)
                      "></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="微信公众号" prop="wechat" class="title-item">
                  <el-input v-model.trim="dataPO.wechat" placeholder="请输入微信公众号" maxlength="20" :show-word-limit="true"
                    :disabled="(disabled && dataPO.clientType == '60661001') ||
                      (!!this.renewData.wechat &&
                        dataPO.clientType == '60661001') ||
                      (dataPO.clientType == '60661001' &&
                        columnUpdateState.wechat == 1)
                      "></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-show="dataPO.clientType == '60661001'">
                <el-form-item label="企业官网" prop="websiteLink" class="title-item">
                  <el-input v-model.trim="dataPO.websiteLink" placeholder="请输入企业官网" maxlength="100"
                    @change="handleInputUrl" :disabled="disabled ||
                      !!this.renewData.websiteLink ||
                      columnUpdateState.websiteLink == 1
                      ">
                    <el-select v-model="netHead" slot="prepend" placeholder="请选择" @change="handleSelectHead" :disabled="disabled ||
                      !!this.renewData.websiteLink ||
                      columnUpdateState.websiteLink == 1
                      ">
                      <el-option v-for="(item, index) in netHeadList" :label="item.label" :value="item.value"
                        :key="index"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-show="dataPO.clientType == '60661001'">
                <el-form-item label="企业简介" prop="introduction" class="area-item">
                  <el-input type="textarea" v-model.trim="dataPO.introduction" placeholder="请输入企业简介" :rows="8"
                    maxlength="1000" :show-word-limit="true" resize="none" :disabled="disabled ||
                      !!this.renewData.introduction ||
                      columnUpdateState.introduction == 1
                      "></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-show="dataPO.clientType == '60661002'">
                <el-form-item label="客户简介" prop="introduction" class="area-item">
                  <el-input type="textarea" v-model.trim="dataPO.introduction" placeholder="请输入客户简介" :rows="8"
                    :show-word-limit="true" maxlength="1000" resize="none"></el-input>
                </el-form-item>
              </el-col>

              <el-col>
                <el-form-item class="btn-item">
                  <el-button @click="joinMyClient()" v-show="flag" type="primary">加入客户列表</el-button>
                  <el-button @click="cancel()" v-show="!flag">取消</el-button>
                  <el-button type="primary" @click="submitForm('form')"
                    :disabled="disabled && dataPO.clientType == '60661001'" v-show="!flag">确认</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </Modal>
      </div>
      <!-- 共享   dialog -->
      <div class="share-box">
        <Modal :visible.sync="shareVisible" @reset="resetShareFields" class="dialog-share-style">
          <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
            {{ shareTitle }}
          </div>
          <el-form :model="shareDataPO" ref="shareForm" label-width="100px" class="demo-ruleForm">
            <div class="share-top">
              <div class="share-title">
                <div class="title">
                  <span class="big-txt">指定范围内共享</span>
                  <span class="small-txt">（支持指定范围内人员查看）</span>
                </div>
                <div class="right-btn" v-show="shareTitle == '查看分享'" @click="cancelShare()">
                  <img @dragstart.prevent src="@/assets/img/user/yigongxiang.png" alt="" width="100%" height="100%"
                    class="item-icon" />
                  <span class="item-txt">取消共享</span>
                </div>
              </div>
            </div>
            <el-row>
              <el-col :span="24">
                <el-form-item label="资料可见范围" prop="file">
                  <el-checkbox v-model="shareDataPO.allSelect" :label="'全选'" @change="handleCheckAll()"></el-checkbox>
                  <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left: -115px">
                    <el-tab-pane label="项目跟踪记录" name="trackRecord"></el-tab-pane>
                    <el-tab-pane label="客户资料库" name="customerFile"></el-tab-pane>
                  </el-tabs>
                </el-form-item>
                <!-- 项目跟踪记录 -->
                <div class="share-none" v-show="activeName == 'trackRecord' &&
                  this.shareDataPO.recordGroup.length == 0
                  ">
                  暂无项目跟踪记录
                </div>
                <div class="share-record" v-show="activeName == 'trackRecord' &&
                  this.shareDataPO.recordGroup.length > 0
                  ">
                  <el-checkbox v-model="shareDataPO.allProjectSelect" :label="'全选'"
                    @change="handleCheckAllProject()"></el-checkbox>
                  <div class="item-group">
                    <div class="record-item">
                      <span class="record" v-for="item in shareDataPO.recordGroup" :key="item.projecId">
                        <el-checkbox v-model="item.select" @change="handleCheckProject(item)">
                        </el-checkbox>
                        <span class="record-name">
                          <span :title="item.projectName">{{
                            item.projectName
                          }}</span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <!-- <div class="pagination-box">
                  <pagination-record
                    ref="paginationRecord"
                    :total="recordTotal"
                    @change="paginChangeRecord"
                    v-show="recordTotal != 0"
                  >
                  </pagination-record>
                </div> -->
                </div>
                <!-- 客户资料库 -->
                <div class="share-none" v-show="activeName == 'customerFile' &&
                  this.shareDataPO.shareFileRange &&
                  this.shareDataPO.shareFileRange.length == 0
                  ">
                  暂无客户资料信息
                </div>
                <div class="share-file" v-show="activeName == 'customerFile' &&
                  this.shareDataPO.shareFileRange &&
                  this.shareDataPO.shareFileRange.length > 0
                  ">
                  <el-checkbox v-model="shareDataPO.allFilefolderSelect" :label="'全选'"
                    @change="handleCheckAllFilefolder()"></el-checkbox>
                  <div class="item" v-for="item in shareDataPO.shareFileRange" :key="item.folderId">
                    <div class="item-top">
                      <el-checkbox v-model="item.select" @change="handleCheckFilefolder(item)">
                      </el-checkbox>
                      <div class="file-name" @click="handleClickFilefolder(item)">
                        <img @dragstart.prevent src="@/assets/img/user/wenjian.png" alt="" width="100%" height="100%"
                          class="item-icon" />
                        {{ item.folderName }}
                        <img v-show="item.isFile == 1" @dragstart.prevent src="@/assets/img/user/xia.png" alt=""
                          width="100%" height="100%" class="item-select-icon" />
                      </div>
                    </div>
                    <div class="menu" v-show="item.menuFlag">
                      <div v-for="items in item.fileTable" :key="items.folderFileId">
                        <el-checkbox v-model="items.select" @change="handleCheckFile(items, item)">
                        </el-checkbox>
                        <span style="margin-left: 10px" :title="items.fileName">{{ items.fileName }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="divider"></div>
              </el-col>
              <el-col :span="24" style="margin-top: 20px">
                <el-form-item label="选择可见范围" prop="range">
                  <!-- <el-input
                  v-model.trim="inputValue"
                  placeholder="请输入手机号"
                  clearable
                  @blur="handleInputConfirm"
                  :disabled="
                    shareDataPO.personnelIds &&
                      shareDataPO.personnelIds.length > 49
                  "
                ></el-input> -->
                  <el-autocomplete v-model.trim="inputValue" placeholder="填写手机号分享" clearable :maxlength="11"
                    :fetch-suggestions="getShareConsumerInfo" :trigger-on-focus="false" @select="handleSelect" :disabled="shareDataPO.personnelIds &&
                      shareDataPO.personnelIds.length > 49
                      ">
                    <template slot-scope="{ item }">
                      <div>
                        {{ item.phone }}
                      </div>
                    </template>
                  </el-autocomplete>
                </el-form-item>
                <div class="share-bottom">
                  <span class="share-select">待分享（{{
                    (shareDataPO.personnelIds &&
                      shareDataPO.personnelIds.length) ||
                    0
                  }}/50）</span>
                  <div class="share-range">
                    <el-tag :key="tag" v-for="tag in shareDataPO.phoneGroup" closable :disable-transitions="false"
                      @close="handleClose(tag)">
                      {{ tag }}
                    </el-tag>
                  </div>
                </div>
              </el-col>

              <el-col>
                <el-form-item class="btn-item">
                  <el-button @click="cancelForm()">取消</el-button>
                  <el-button type="primary" @click="submitShareForm('shareForm')">确认</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import PaginationRecord from "@/components/PaginationRecord";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
import {
  validatePhone,
  validateCode,
  checkPhone,
  price
} from "@/utils/validate.js";
export default {
  components: {
    Pagination,
    PaginationRecord,
    Empty,
    Modal
  },
  data() {
    return {
      disabled: true,
      typeDisabled: false,
      columnUpdateState: {},
      renewData: {},
      flag: false,
      total: 0,
      tableData: [],
      visible: false,
      customerTitle: "新建客户",
      showBtnDealImg: true,
      noneBtnImg: false,
      uploadImgUrl: process.env.VUE_APP_BASE_URL + "/mds/api/file/upload", // 上传图片服务器地址
      headerObj: {
        Authorization: this.$session.getLoginToken()
      },
      imgDisabled: false,
      imgDialogVisible: false,
      dialogImageUrl: "",
      fileList: [], // 上传的logo
      imgUrl: "",
      dataPO: {
        clientType: "60661001"
      },

      shareVisible: false,
      shareTitle: "共享设置",
      // 共享弹窗数据
      shareDataPO: {
        personnelIds: [], //共享用户的id
        phoneGroup: [], //共享用户手机号
        shareFileRange: [], //文件夹和文件
        fileIds: [], //文件id
        folderIds: [], //文件夹id
        projectIds: [], //项目id
        recordGroup: [], //项目

        allSelect: false, // 全部全选
        allProjectSelect: false, //项目列表全选
        allFilefolderSelect: false, //文件夹列表全选
        allFileSelect: false //文件列表全选
      },
      customerTypeList: [
        {
          label: "企业客户",
          value: "60661001"
        },
        {
          label: "非企业客户",
          value: "60661002"
        }
      ],
      rules: {
        clientName: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur"
          }
        ],
        province: [
          {
            required: true,
            message: "请选择省份",
            trigger: "blur"
          }
        ],
        address: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur"
          }
        ],
        phone: [
          {
            validator: validatePhone,
            trigger: "blur"
          }
        ],
        creditCode: [
          {
            required: true,
            message: "统一社会信用代码不能为空",
            trigger: "blur"
          },
          {
            validator: validateCode
          }
        ]
        // registeredCapital: [
        //   {
        //     validator: price,
        //     trigger: "blur"
        //   }
        // ]
      },
      params: {
        pageNum: 1,
        pageSize: 10
      },
      provinceList: [],
      cityList: [],
      countyList: [],
      inputValue: "",
      activeName: "trackRecord",
      recordTotal: 15,
      paramsRecord: {
        pageNum: 1,
        pageSize: 15
      },
      id: "",
      shareData: {}, //已共享的数据集合
      menuFlag: false,
      consumerId: "",
      netHeadList: [
        {
          label: "http",
          value: "http"
        },
        {
          label: "https",
          value: "https"
        }
      ],
      netHead: "http",
      linkUrl: ""
    };
  },
  computed: {},
  watch: {
    disabled: {
      handler(newName, oldName) { },
      immediate: true
    }
  },
  created() {
    this.consumerId = this.$session.getUsers().consumerId;
  },
  mounted() {
    this.getAddress();
    this.consumerClient();
  },
  methods: {
    coloring() {
      let colors = ["#90cfb7", "#8ca3cc", "#e8ab6f", "#6daae2", "#c8b7d8"];
      let index = Math.floor(Math.random() * 5);
      return colors[index];
    },
    //
    handleSelectHead(val) {
      this.netHead = val;
      this.linkUrl = this.netHead + "://" + this.dataPO.linkUrl;
    },
    //
    handleInputUrl(val) {
      if (val) {
        if (val.indexOf("https") != -1) {
          this.dataPO.websiteLink = val.slice(
            8,
            this.dataPO.websiteLink.length
          );
          this.linkUrl = this.netHead + "://" + this.dataPO.websiteLink;
        } else if (val.indexOf("http") != -1 && val.indexOf("https") == -1) {
          this.dataPO.websiteLink = val.slice(
            7,
            this.dataPO.websiteLink.length
          );
          this.linkUrl = this.netHead + "://" + this.dataPO.websiteLink;
        } else {
          this.dataPO.websiteLink = val;
          this.linkUrl = this.netHead + "://" + this.dataPO.websiteLink;
        }
        this.$forceUpdate();
      } else {
        this.dataPO.websiteLink = val;
        this.linkUrl = val;
      }
    },
    // 获取列表
    consumerClient() {
      this.$api.personalCenter.consumerClient({ ...this.params }).then(res => {
        res.rows.forEach(item => {
          if (!item.logoUrl) {
            this.$api.industry
              .getCompanyName({
                companyName: item.clientName
              })
              .then(ress => {
                item.logoImg = ress.msg;
                // item.bcColor = this.coloring();
                this.$forceUpdate();
              })
              .catch(msg => {
                if (msg?.msg) {
                  this.$message.error(msg?.msg);
                }
              });
          }
          // if (item.consumerId == this.consumerId) {
          //   item.delflag = true;
          // } else {
          //   item.delflag = false;
          // }
        });
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    // 获取省获取市数据
    getProvinceCity(val1, val2) {
      this.cityList = [];
      this.countyList = [];
      this.provinceList.forEach(item => {
        if (val1 == item.code) {
          this.cityList = item.childrenList;
          this.cityList.forEach(items => {
            if (val2 == items.code) {
              this.countyList = items.childrenList;
            }
          });
        }
      });
    },
    // 获取省市区数据
    getAddress() {
      this.$api.personalCenter.getAddress().then(res => {
        this.provinceList = res.data;
      });
    },
    // 选取省获取市数据
    choseProvince(val) {
      this.cityList = [];
      this.countyList = [];
      this.dataPO.city = "";
      this.dataPO.county = "";
      this.provinceList.forEach(item => {
        if (val == item.code) {
          this.cityList = item.childrenList;
        }
      });
    },
    // 选取市获取区数据
    choseCity(val) {
      this.countyList = [];
      this.dataPO.county = "";
      this.provinceList.forEach(item => {
        item.childrenList.forEach(items => {
          if (val == items.code) {
            this.countyList = items.childrenList;
          }
        });
      });
    },
    // 点击进入客户详情
    handleDetail(val,item) {
      let type = val.clientType == "60661001" ? 1 : 2;
      let pathType = 2;
      let dynamic = item  ? item : "";
      // this.$router.push(
      //   `/user/my-collect/customer/detail?id=${val.clientId}&type=${type}`
      // );
      let url = this.$router.resolve({
        path: `/client/consumer-detail?id=${val.clientId}&type=${type}&pathType=${pathType}&dynamic=${dynamic}`
      });
      window.open(url.href, "_blank");
    },
    // 切换分页
    paginChange(page, pageSize) {
      this.params.pageNum = page;
      this.params.pageSize = pageSize;
      this.consumerClient();
    },
    // 新建客户按钮
    handleAddCustomer() {
      this.typeDisabled = false;
      this.columnUpdateState = {};
      this.imgDisabled = false;
      this.renewData = {};

      this.visible = true;
      this.customerTitle = "新建客户";
      this.flag = false;
      this.disabled = true;
      // this.resetFields();
    },
    // 修改按钮
    async editCustomer(row) {
      this.typeDisabled = true;
      this.disabled = false;
      this.imgDisabled = true;
      this.renewData = {};

      this.visible = true;
      this.customerTitle = "修改客户";

      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: true
      });
      await this.$api.personalCenter
        .getClientInfo({ clientId: row.clientId })
        .then(res => {
          this.dataPO = res.data;
          if (this.dataPO.websiteLink) {
            if (this.dataPO.websiteLink.indexOf("https") != -1) {
              this.netHead = "https";
              this.dataPO.websiteLink = this.dataPO.websiteLink.slice(
                8,
                this.dataPO.websiteLink.length
              );
              this.linkUrl = this.netHead + "://" + this.dataPO.websiteLink;
              this.$forceUpdate();
            } else if (
              this.dataPO.websiteLink.indexOf("http") != -1 &&
              this.dataPO.websiteLink.indexOf("https") == -1
            ) {
              this.netHead = "http";
              this.dataPO.websiteLink = this.dataPO.websiteLink.slice(
                7,
                this.dataPO.websiteLink.length
              );
              this.linkUrl = this.netHead + "://" + this.dataPO.websiteLink;
              this.$forceUpdate();
            }
            this.$forceUpdate();
          }
          if (
            res.data.columnUpdateState &&
            this.dataPO.clientType == "60661001"
          ) {
            this.columnUpdateState = JSON.parse(res.data.columnUpdateState);
          }
          loading.close();
        })
        .catch(msg => {
          loading.close();
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
      await this.getProvinceCity(this.dataPO.province, this.dataPO.city);
      if (row.logo) {
        await this.$api.personalCenter
          .getFileInfo({ fileId: row.logo })
          .then(res => {
            let url =
              process.env.VUE_APP_FILEURL +
              res.data.fileDir +
              "/" +
              res.data.fileKey;
            this.noneBtnImg = true;
            this.fileList.push({ url: url });
            loading.close();
          })
          .catch(msg => {
            loading.close();
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      }
      if (this.dataPO.clientType == "60661001") {
        this.rules["creditCode"] = [
          {
            required: true,
            message: "统一社会信用代码不能为空",
            trigger: "blur"
          },
          {
            validator: validateCode
          }
        ];
        this.$forceUpdate();
      } else if (this.dataPO.clientType == "60661002") {
        this.rules["creditCode"] = [];
        this.$refs.form.clearValidate("creditCode");
        this.$forceUpdate();
      }
    },
    // 切换客户名称按钮
    handleChangeCustomerType(val) {
      console.log(val);
      this.rules["creditCode"] = [];
      this.$refs.form.clearValidate("creditCode");
      this.$forceUpdate();
      if (val == "60661001") {
        this.rules["creditCode"] = [
          {
            required: true,
            message: "统一社会信用代码不能为空",
            trigger: "blur"
          },
          {
            validator: validateCode
          }
        ];
        this.$forceUpdate();
        if (this.dataPO.clientId) {
          this.flag = true;
        }
        this.dataPO = {
          clientType: "60661001",
          clientId: undefined,
          clientName: undefined,
          province: undefined,
          city: undefined,
          county: undefined,
          phone: undefined,
          creditCode: undefined,
          logo: undefined,
          legalPerson: undefined,
          registeredCapital: undefined,
          establishDate: undefined,
          address: undefined,
          wechat: undefined,
          websiteLink: undefined,
          introduction: undefined
        };
      } else if (val == "60661002") {
        this.rules["creditCode"] = [];
        this.$refs.form.clearValidate("creditCode");
        this.$forceUpdate();
        this.flag = false;
        this.disabled = true;
        this.fileList = [];
        this.noneBtnImg = false;
        this.dataPO = {
          clientType: "60661002",
          clientId: undefined,
          clientName: undefined,
          province: undefined,
          city: undefined,
          county: undefined,
          phone: undefined,
          creditCode: undefined,
          logo: undefined,
          legalPerson: undefined,
          registeredCapital: undefined,
          establishDate: undefined,
          address: undefined,
          wechat: undefined,
          websiteLink: undefined,
          introduction: undefined
        };
      }
      this.$forceUpdate();
    },
    // 同步按钮
    async hangdleRenew(creditCode) {
      const reg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
      if (creditCode && reg.test(creditCode)) {
        const loading = this.$loading({
          lock: true,
          text: "加载中...",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.3)",
          fullscreen: true
        });
        await this.$api.personalCenter
          .synchronousData({ creditCode: creditCode })
          .then(res => {
            if (res.data.clientName) {
              this.renewData = JSON.parse(JSON.stringify(res.data));
              // this.dataPO.clientType = "60661001";
              // this.dataPO = res.data;
              this.dataPO.address = res.data.address;
              this.dataPO.area = res.data.area;
              this.dataPO.city = res.data.city;
              this.dataPO.county = res.data.county;
              this.dataPO.clientName = res.data.clientName;
              this.dataPO.creditCode = res.data.creditCode;
              this.dataPO.establishDate = res.data.establishDate;
              this.dataPO.legalPerson = res.data.legalPerson;
              this.dataPO.province = res.data.province;
              this.dataPO.registeredCapital = res.data.registeredCapital;
              this.dataPO.clientId = res.data.clientId;
              this.disabled = false;
              if (res.data.clientId) {
                this.dataPO.phone = res.data.phone;
                this.dataPO.introduction = res.data.introduction;
                // this.dataPO.websiteLink = res.data.websiteLink;
                this.dataPO.wechat = res.data.wechat;
                if (res.data.websiteLink) {
                  if (res.data.websiteLink.indexOf("https") != -1) {
                    this.netHead = "https";
                    this.dataPO.websiteLink = res.data.websiteLink.slice(
                      8,
                      res.data.websiteLink.length
                    );
                    this.linkUrl =
                      this.netHead + "://" + this.dataPO.websiteLink;
                    this.$forceUpdate();
                  } else if (
                    res.data.websiteLink.indexOf("http") != -1 &&
                    res.data.websiteLink.indexOf("https") == -1
                  ) {
                    this.netHead = "http";
                    this.dataPO.websiteLink = res.data.websiteLink.slice(
                      7,
                      res.data.websiteLink.length
                    );
                    this.linkUrl =
                      this.netHead + "://" + this.dataPO.websiteLink;
                    this.$forceUpdate();
                  }
                  this.$forceUpdate();
                }
              } else {
              }
              loading.close();
            } else if (!res.data.clientName) {
              this.$message.error("暂未查询到相关企业信息，请手动添加");
              // this.dataPO = {};
              this.flag = false;
              this.disabled = false;
              this.fileList = [];
              this.noneBtnImg = false;
              this.renewData = {};
              // this.dataPO.clientType = "60661001";
              // this.dataPO.address = "";
              // this.dataPO.area = "";
              // this.dataPO.city = "";
              // this.dataPO.county = "";
              // this.dataPO.clientName = "";
              this.dataPO.creditCode = creditCode;
              // this.dataPO.establishDate = "";
              // this.dataPO.legalPerson = "";
              // this.dataPO.province = "";
              // this.dataPO.registeredCapital = "";
              loading.close();
              return;
            }
          })
          .catch(msg => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
            // this.$message.error("暂未查询到相关企业信息，请手动添加");
            // this.dataPO.clientType = "60661001";
            // this.dataPO = {};

            // this.flag = false;
            // this.disabled = false;
            // this.fileList = [];
            // this.noneBtnImg = false;
            // this.renewData = {};
            // this.dataPO.address = "";
            // this.dataPO.area = "";
            // this.dataPO.city = "";
            // this.dataPO.county = "";
            // this.dataPO.clientName = "";
            // this.dataPO.creditCode = creditCode;
            // this.dataPO.establishDate = "";
            // this.dataPO.legalPerson = "";
            // this.dataPO.province = "";
            // this.dataPO.registeredCapital = "";
            loading.close();
          });
        await this.getProvinceCity(this.dataPO.province, this.dataPO.city);
        if (this.renewData.logo) {
          this.fileList = [];
          await this.$api.personalCenter
            .getFileInfo({ fileId: this.renewData.logo })
            .then(res => {
              let url =
                process.env.VUE_APP_FILEURL +
                res.data.fileDir +
                "/" +
                res.data.fileKey;
              this.noneBtnImg = true;
              this.fileList.push({ url: url });
            })
            .catch(msg => {
              loading.close();
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        }
        if (this.renewData.clientId) {
          await this.$api.personalCenter
            .isMyClient({ clientId: this.dataPO.clientId })
            .then(res => {
              if (res.data == 0) {
                this.disabled = true;
                this.flag = true;
              } else {
                this.$message({
                  message: "当前客户已存在于我的客户列表中，暂不支持操作",
                  type: "warning",
                  duration: 5000
                });
                this.flag = false;
                this.disabled = true;
              }
            })
            .catch(msg => {
              loading.close();
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        } else {
          this.flag = false;
        }
      } else {
        this.$message.error("统一社会信用代码错误");
        return false;
      }
    },
    // 加入-移除客户列表
    joinMyClient() {
      this.$api.industry
        .joinMyClient({
          clientId: this.dataPO.clientId
        })
        .then(() => {
          this.$message.success("已加入客户列表");
          this.visible = false;
          this.fileList = [];
          this.noneBtnImg = false;
          this.resetFields();
          this.consumerClient();
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 上传封面图片方法
    handleChangePic(file, fileList) { },
    handlePicSuccess(res, file, filer) {
      if (res.code == 200) {
        this.noneBtnImg = true;
        this.$message.success("上传成功");
        this.dataPO.logo = res.data.fileId;
        this.$refs.form.clearValidate("logo");
      } else {
        this.$refs.uploadPic.handleRemove(file);
        this.$message.error("上传失败");
      }
    },
    beforePicUpload(file) {
      if (
        file.name.toLowerCase().indexOf("jpg") == -1 &&
        file.name.toLowerCase().indexOf("jpeg") == -1 &&
        file.name.toLowerCase().indexOf("png") == -1
      ) {
        this.$message.error("请上传jpg/jpeg/png格式图片！");
        return false;
      } else {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error("上传企业logo大小不能超过5M!");
        }
        return isLt5M;
      }
    },
    handleRemove(file, fileList) {
      this.$refs.uploadPic.handleRemove(file);
      this.dataPO.logo = "";
      this.noneBtnImg = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgDialogVisible = true;
    },

    // 我的客户-分享获取
    async getShareClientInfo(val) {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: true
      });
      await this.$api.personalCenter
        .getShareClientInfo({ clientId: val })
        .then(res => {
          if (res.data.consumerShareId) {
            this.shareVisible = true;
            this.shareDataPO.consumerShareId = res.data.consumerShareId;
            res.data.consumerAccountList.forEach(item => {
              this.shareDataPO.phoneGroup.push(item.account);
              this.shareDataPO.personnelIds.push(item.consumerId);
            });
            this.shareData = res.data;
          } else {
            this.$message.error("被共享者已移除该共享客户，请重新共享！");
            this.consumerClient();
          }
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
          loading.close();
        });
      await this.$api.personalCenter
        .getClientProjectNoPage({
          clientId: this.id
          // ...this.paramsRecord
        })
        .then(res => {
          this.shareDataPO.recordGroup = res.data;
          // this.recordTotal = res.data.total;
          let isAll = true;
          console.log(this.shareData);
          this.shareDataPO.recordGroup.forEach(item => {
            if (
              this.shareData.projectIds &&
              this.shareData.projectIds.indexOf(item.projectId) == -1
            ) {
              item.select = false;
              isAll = false;
            } else if (
              this.shareData.projectIds &&
              this.shareData.projectIds.indexOf(item.projectId) != -1
            ) {
              item.select = true;
            } else if (!this.shareData.projectIds) {
              isAll = false;
            }
          });
          this.shareDataPO.allProjectSelect = isAll;
          this.$forceUpdate();
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
      await this.$api.personalCenter
        .myClientInfoArchivesFolder({
          clientId: this.id
        })
        .then(res => {
          this.shareDataPO.shareFileRange = res.data;
          let isAll = true;
          this.shareDataPO.shareFileRange.forEach(item => {
            if (
              this.shareData.folderIds &&
              this.shareData.folderIds.indexOf(item.folderId) == -1
            ) {
              item.select = false;
              isAll = false;
            } else if (
              this.shareData.folderIds &&
              this.shareData.folderIds.indexOf(item.folderId) != -1
            ) {
              item.select = true;
            } else if (!this.shareData.folderIds) {
              isAll = false;
            }
          });
          this.shareDataPO.allFilefolderSelect = isAll;
          this.$forceUpdate();
          if (
            this.shareDataPO.allProjectSelect &&
            this.shareDataPO.allFilefolderSelect
          ) {
            this.shareDataPO.allSelect = true;
          } else {
            this.shareDataPO.allSelect = false;
          }
          loading.close();
        })
        .catch(msg => {
          loading.close();
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 获取项目跟踪记录
    getClientProject() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: true
      });
      this.$api.personalCenter
        .getClientProjectNoPage({
          clientId: this.id
          // ...this.paramsRecord
        })
        .then(res => {
          this.shareDataPO.recordGroup = res.data;
          // this.recordTotal = res.data.total;
          this.shareDataPO.recordGroup.forEach(item => {
            item.select = false;
          });
          loading.close();
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
          loading.close();
        });
    },
    // 我的客户  获取文件夹列表
    myClientInfoArchivesFolder() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: true
      });
      this.$api.personalCenter
        .myClientInfoArchivesFolder({
          clientId: this.id
        })
        .then(res => {
          this.shareDataPO.shareFileRange = res.data;
          this.shareDataPO.shareFileRange.forEach(item => {
            if (item.isFile == 1) {
              item.menuFlag = false;
              this.$forceUpdate();
            }
          });
          loading.close();
        })
        .catch(msg => {
          loading.close();
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 我的客户  点击文件夹获取文件
    handleClickFilefolder(row) {
      if (!row.isFile) {
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: true
      });
      this.$api.personalCenter
        .myClientInfoArchivesFile({
          folderId: row.folderId,
          clientId: this.id
        })
        .then(res => {
          console.log(this.shareData);
          row.fileTable = res.data;
          row.menuFlag = !row.menuFlag;
          if (this.shareData != {}) {
            row.fileTable.forEach(item => {
              if (
                this.shareData.fileIds &&
                this.shareData.fileIds.indexOf(item.folderFileId) == -1
              ) {
                item.select = false;
                this.$forceUpdate();
              } else if (
                this.shareData.fileIds &&
                this.shareData.fileIds.indexOf(item.folderFileId) != -1
              ) {
                item.select = true;
                this.$forceUpdate();
              }
            });
          }
          this.$forceUpdate();
          loading.close();
        })
        .catch(msg => {
          loading.close();
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 切换分页
    // paginChangeRecord(page, pageSize) {
    //   this.paramsRecord.pageNum = page;
    //   this.paramsRecord.pageSize = pageSize;
    //   this.getClientProject(this.id);
    // },
    // 共享按钮
    shareNewCustomer(row) {
      this.id = row.clientId;
      this.shareVisible = true;
      this.shareTitle = "共享设置";
      this.activeName = "trackRecord";
      this.resetShare();
      this.getClientProject();
      this.myClientInfoArchivesFolder();
    },
    // 已共享按钮
    sharedCustomer(row) {
      // this.shareVisible = true;
      this.shareTitle = "查看分享";
      this.activeName = "trackRecord";
      this.resetShare();
      this.getShareClientInfo(row.clientId);
      this.id = row.clientId;
    },
    // 取消共享按钮
    cancelShare() {
      this.$confirm("确认取消共享吗？", "提示", {
        confirmButtonText: "确认取消",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.shareVisible = false;
          this.$api.personalCenter
            .cancelShareClientInfo({
              consumerShareId: this.shareDataPO.consumerShareId
            })
            .then(res => {
              this.$message({
                type: "success",
                message: "取消成功!"
              });
              this.consumerClient();
            })
            .catch(msg => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        })
        .catch(() => { });
    },
    // tab切换
    handleClick(tab, event) { },
    // 全部全选
    handleCheckAll() {
      let { shareFileRange, recordGroup } = this.shareDataPO;
      if (this.shareDataPO.allSelect) {
        this.shareDataPO.allFilefolderSelect = true;
        this.shareDataPO.allProjectSelect = true;
        shareFileRange.forEach(item => {
          item.select = true;
        });
        recordGroup.forEach(item => {
          item.select = true;
        });
      } else {
        this.shareDataPO.allFilefolderSelect = false;
        this.shareDataPO.allProjectSelect = false;
        shareFileRange.forEach(item => {
          item.select = false;
        });
        recordGroup.forEach(item => {
          item.select = false;
        });
      }
    },
    // 项目全选
    handleCheckAllProject() {
      let { recordGroup } = this.shareDataPO;
      if (this.shareDataPO.allProjectSelect) {
        recordGroup.forEach(item => {
          item.select = true;
        });
        if (this.shareDataPO.allFilefolderSelect) {
          this.shareDataPO.allSelect = true;
        } else {
          this.shareDataPO.allSelect = false;
        }
      } else {
        this.shareDataPO.allSelect = false;
        recordGroup.forEach(item => {
          item.select = false;
        });
      }
    },
    // 项目单选
    handleCheckProject(val) {
      let { recordGroup, shareFileRange } = this.shareDataPO;
      let isAll = true;
      let arr = [];
      recordGroup.forEach(item => {
        if (!item.select) {
          isAll = false;
          this.shareDataPO.allSelect = false;
          this.$forceUpdate();
        }

        // arr.push(item.select);
        // if(arr.indexOf('false') == -1) {

        // }
      });
      this.shareDataPO.allProjectSelect = isAll;
      if (
        this.shareDataPO.allProjectSelect &&
        this.shareDataPO.allFilefolderSelect
      ) {
        this.shareDataPO.allSelect = true;
      } else {
        this.shareDataPO.allSelect = false;
      }
      this.$forceUpdate();
    },
    // 文件夹全选
    handleCheckAllFilefolder() {
      let { shareFileRange } = this.shareDataPO;
      if (this.shareDataPO.allFilefolderSelect) {
        shareFileRange.forEach(item => {
          item.select = true;
        });
        if (this.shareDataPO.allProjectSelect) {
          this.shareDataPO.allSelect = true;
        } else {
          this.shareDataPO.allSelect = false;
        }
      } else {
        this.shareDataPO.allSelect = false;
        shareFileRange.forEach(item => {
          item.select = false;
        });
      }
    },
    // 文件夹单选
    handleCheckFilefolder(val) {
      console.log(val);
      if (val.select && val.fileTable) {
        val.fileTable.forEach(items => {
          items.select = false;
          this.$forceUpdate();
        });
      }
      let { shareFileRange } = this.shareDataPO;
      let isAll = true;
      shareFileRange.forEach(item => {
        if (!item.select) {
          isAll = false;
          this.shareDataPO.allSelect = false;
        }
      });
      this.shareDataPO.allFilefolderSelect = isAll;
      if (
        this.shareDataPO.allProjectSelect &&
        this.shareDataPO.allFilefolderSelect
      ) {
        this.shareDataPO.allSelect = true;
      } else {
        this.shareDataPO.allSelect = false;
      }
      this.$forceUpdate();
    },
    // 文件单选
    handleCheckFile(val, pval) {
      console.log(val, pval);
      if (val.select) {
        pval.select = false;
        this.shareDataPO.allFilefolderSelect = false;
        this.shareDataPO.allSelect = false;
        this.$forceUpdate();
      }
      this.$forceUpdate();
    },
    // 删除按钮
    delCustomer(row) {
      this.$confirm("确认移除客户吗？移除后不可恢复自定义添加的信息", "提示", {
        confirmButtonText: "确认移除",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$api.industry
          .joinMyClient({
            clientId: row.clientId
          })
          .then(() => {
            this.$message.success("已从客户列表移除");
            this.consumerClient();
          })
          .catch(msg => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      });
    },
    // 关闭弹窗时重置表单
    resetFields() {
      this.$refs.form.resetFields();
      this.fileList = [];
      this.noneBtnImg = false;
      this.dataPO = {
        clientType: "60661001",
        clientId: undefined,
        clientName: undefined,
        province: undefined,
        city: undefined,
        county: undefined,
        phone: undefined,
        creditCode: undefined,
        logo: undefined,
        legalPerson: undefined,
        registeredCapital: undefined,
        establishDate: undefined,
        address: undefined,
        wechat: undefined,
        websiteLink: undefined,
        introduction: undefined
      };
    },
    resetShareFields() {
      this.$refs.shareForm.resetFields();
      this.shareDataPO = {
        personnelIds: [], //共享用户的id
        phoneGroup: [], //共享用户手机号
        shareFileRange: [], //文件夹和文件
        fileIds: [], //文件id
        folderIds: [], //文件夹id
        projectIds: [], //项目id
        recordGroup: [], //项目

        allSelect: false, // 全部全选
        allProjectSelect: false, //项目列表全选
        allFilefolderSelect: false, //文件夹列表全选
        allFileSelect: false //文件列表全选
      };
    },
    resetShare() {
      this.shareDataPO = {
        personnelIds: [], //共享用户的id
        phoneGroup: [], //共享用户手机号
        shareFileRange: [], //文件夹和文件
        fileIds: [], //文件id
        folderIds: [], //文件夹id
        projectIds: [], //项目id
        recordGroup: [], //项目

        allSelect: false, // 全部全选
        allProjectSelect: false, //项目列表全选
        allFilefolderSelect: false, //文件夹列表全选
        allFileSelect: false //文件列表全选
      };
    },
    // 切换推送时间按钮
    handleChangeSendTime(val) {
      if (val == "60701002") {
        this.timeFlag = true;
      } else if (val == "60701001") {
        this.timeFlag = false;
      }
    },
    // 输入手机号  联想搜索
    getShareConsumerInfo(queryString, callback) {
      if (queryString.length > 2) {
        this.$api.personalCenter
          .getShareConsumerInfo({ phone: queryString })
          .then(res => {
            let newData = [];
            res.data.forEach(item => {
              let obj = {
                phone: item.phone,
                consumerId: item.consumerId
              };
              newData.push(obj);
            });
            callback(newData);
          })
          .catch(msg => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      }
    },
    // 输入手机号  选中手机号
    handleSelect(item) {
      console.log(item);
      this.inputValue = "";
      if (this.shareDataPO.phoneGroup) {
        if (this.shareDataPO.phoneGroup.indexOf(item.phone) == -1) {
          this.shareDataPO.phoneGroup.push(item.phone);
          this.shareDataPO.personnelIds.push(item.consumerId);
        } else {
          this.$message.error("手机号重复，请重新输入！");
          return false;
        }
      }
    },
    // 标签事件方法
    handleClose(tag) {
      this.shareDataPO.personnelIds.splice(
        this.shareDataPO.personnelIds.indexOf(tag),
        1
      );
      this.shareDataPO.phoneGroup.splice(
        this.shareDataPO.phoneGroup.indexOf(tag),
        1
      );
      this.$forceUpdate();
    },
    // 表单提交事件
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.dataPO.clientId) {
            this.$api.personalCenter
              .updateClientInfo({
                ...this.dataPO,
                websiteLink: this.linkUrl ? this.linkUrl : this.dataPO.linkUrl
              })
              .then(res => {
                this.visible = false;
                this.$message.success("修改成功");
                this.fileList = [];
                this.noneBtnImg = false;
                this.resetFields();
                this.consumerClient();
              })
              .catch(msg => {
                if (msg?.msg) {
                  this.$message.error(msg?.msg);
                }
              });
          } else {
            this.$api.personalCenter
              .insertClientInfo({
                ...this.dataPO,
                websiteLink: this.linkUrl ? this.linkUrl : this.dataPO.linkUrl
              })
              .then(res => {
                this.visible = false;
                this.$message.success("新增成功");
                this.fileList = [];
                this.noneBtnImg = false;
                this.$api.industry
                  .joinMyClient({
                    clientId: res.data.clientId
                  })
                  .then(() => {
                    this.resetFields();
                    this.consumerClient();
                  })
                  .catch(msg => {
                    if (msg?.msg) {
                      this.$message.error(msg?.msg);
                    }
                  });
              })
              .catch(msg => {
                if (msg?.msg) {
                  this.$message.error(msg?.msg);
                }
              });
          }
        } else {
          this.$message.error("提交失败!");
          return false;
        }
      });
    },
    submitShareForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.shareDataPO.allSelect) {
            this.shareDataPO.projectIds = [];
            this.shareDataPO.folderIds = [];
            this.shareDataPO.recordGroup.forEach(item1 => {
              this.shareDataPO.projectIds.push(item1.projectId);
            });
            this.shareDataPO.shareFileRange.forEach(item2 => {
              this.shareDataPO.folderIds.push(item2.folderId);
            });
          } else {
            this.shareDataPO.projectIds = [];
            this.shareDataPO.folderIds = [];
            this.shareDataPO.fileIds = [];
            this.shareDataPO.recordGroup.forEach(item => {
              if (item.select) {
                this.shareDataPO.projectIds.push(item.projectId);
              }
            });
            this.shareDataPO.shareFileRange.forEach(item => {
              if (item.select) {
                this.shareDataPO.folderIds.push(item.folderId);
              }
              if (item.fileTable) {
                item.fileTable.forEach(items => {
                  if (items.select) {
                    this.shareDataPO.fileIds.push(items.folderFileId);
                  }
                });
              }
            });
          }
          this.$forceUpdate();
          // 判空
          if (this.shareDataPO.personnelIds.length == 0) {
            this.$message.error("请填写被共享人手机号！");
            return false;
          } else if (
            this.shareDataPO.projectIds.length == 0 &&
            this.shareDataPO.folderIds.length == 0 &&
            this.shareDataPO.fileIds.length == 0
          ) {
            this.$message.error("请选择共享资料！");
            return false;
          } else {
            if (this.shareDataPO.consumerShareId) {
              this.$api.personalCenter
                .shareClientInfo({
                  clientId: this.id,
                  consumerShareId: this.shareDataPO.consumerShareId,
                  fileIds: this.shareDataPO.fileIds,
                  folderIds: this.shareDataPO.folderIds,
                  personnelIds: this.shareDataPO.personnelIds,
                  projectIds: this.shareDataPO.projectIds
                })

                .then(res => {
                  this.shareVisible = false;
                  this.$message.success("提交成功");
                  this.resetShare();
                  this.consumerClient();
                })
                .catch(msg => {
                  if (msg?.msg) {
                    this.$message.error(msg?.msg);
                  }
                });
            } else {
              this.$api.personalCenter
                .shareClientInfo({
                  clientId: this.id,
                  fileIds: this.shareDataPO.fileIds,
                  folderIds: this.shareDataPO.folderIds,
                  personnelIds: this.shareDataPO.personnelIds,
                  projectIds: this.shareDataPO.projectIds
                })

                .then(res => {
                  this.shareVisible = false;
                  this.$message.success("提交成功");
                  this.resetShare();
                  this.consumerClient();
                })
                .catch(msg => {
                  if (msg?.msg) {
                    this.$message.error(msg?.msg);
                  }
                });
            }
          }
        } else {
          this.$message.error("提交失败!");
          return false;
        }
      });
    },
    // 表单取消
    cancel() {
      this.visible = false;
      this.resetFields();
    },
    cancelForm() {
      this.shareVisible = false;
      this.resetShareFields();
    }
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
